import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldHidden, useMountEffect } from '@dex/bubl-dash';
import { useApp } from '@dex/bubl-dash';
import { useUser } from '@dex/bubl-dash';

const BulkRequestsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, mode } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const app = useApp();

    const [settings] = app.store("appSettings");

    const user = useUser();

    useMountEffect(() => {

        if (mode !== "create") return;

        if (settings?.general?.defaultVendorProductId) {

            form.handleChange({ silent: true, reset: true, name: "productId", value: settings.general.defaultVendorProductId });

        }

        if (app.userIs("vendor", user) && user.vendorId) {

            form.handleChange({ silent: true, reset: true, name: "vendorId", value: user.vendorId });

        }

    });

    return useMemo(() => (

        <Panel
            heading={"Bulk Request Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <FieldHidden
                    form={form}
                    name='productId'
                    defaultValue={form.defaults.productId}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRelationship
                            form={form}
                            label={"Vendor"}
                            name='vendorId'
                            required={false}
                            endpoint={'Vendors'}
                            preload={true}
                            defaultValue={form.defaults.vendorId}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldNumber
                            form={form}
                            label={"Quantity (In Rolls)"}
                            name='quantityInRolls'
                            required={false}
                            defaultValue={form.defaults.quantityInRolls}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default BulkRequestsCreateEditForm;
